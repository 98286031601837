<template>
  <div>
    <!-- #region Form -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("featureCreatePage.pageTitle") }}</strong>  
          </CCardHeader>
          <CCardBody>
            <span class="caption">
              {{ $t("common.createCaption") }}
            </span>
            <!-- NOTE:onsubmitの記述によってテキストボックスにフォーカスしてもEnterキー操作を無効化 -->
            <CForm class="submit_form" @submit.prevent>
              <!-- #region タイプ -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("featureCreatePage.typeLabel") }}</strong></CCol>
                <CCol sm="2">
                  <CSelect
                    :value.sync="selectedType"
                    :options="FEATURE_TYPE_LABEL"
                  />
                </CCol>
              </CRow>
              <!-- #endregion タイプ -->
              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("common.name")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput
                  type="text"
                    v-model="requestJa.name"
                    @keydown.enter="onEnterKeyDown($event)"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.requestJa.name.$dirty && !$v.requestJa.name.required
                    "
                  >
                    {{
                      $t("validations.required", { vName: $t("common.name") })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.requestJa.name.$dirty && !$v.requestJa.name.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("common.name"),
                        vMax: $v.requestJa.name.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"
                  ><strong>{{ $t("common.description") }}</strong></CCol
                >
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="requestJa.description"
                    @keydown.enter="onEnterKeyDown($event)"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.requestJa.description.$dirty &&
                      !$v.requestJa.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("common.description"),
                        vMax: $v.requestJa.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <CButton color="secondary" class="" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Form -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">
        <!-- #region 名称 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("featureCreatePage.typeLabel") }}</strong></CCol>
          <CCol>
            <CSelect
              :plaintext="true"
              :disabled="true"
              :value.sync="selectedType"
              :options="FEATURE_TYPE_LABEL"
            />
          </CCol>
        </CRow>

        <!-- #region 名称 -->
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("common.name") }}</strong></CCol
          >
          <CCol
            ><span> {{ requestJa.name }} </span></CCol
          >
        </CRow>
        <!-- #endregion 名称 -->

        <!-- #region 説明 -->
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("common.description") }}</strong></CCol
          >
          <CCol
            ><span> {{ requestJa.description }} </span></CCol
          >
        </CRow>
        <!-- #endregion 説明 -->
      </CForm>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton @click="checkModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton color="success" @click="onRegisterClicked()">
          <i class="icon cil-save mr-1"></i> {{ $t("common.register") }}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Check -->

    <SuccessModal :successModal="successModal" @close="successModal = $event" />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
// import i18n from "@/i18n";
import FEATURE_TYPE_LABEL from "@/mixins/property";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "FeatureCreate",
  mixins: [FEATURE_TYPE_LABEL],


  computed: {
    activeStatus() {
      return this.$v.requestJa.$invalid ? "inactive" : "active";
    },

  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag
      //#region Message
      errorMessage: "error",
      //#endregion Message

      //#region request
      // 日本語入力データ
      // TODO:開発用(言語jaにしている)
      requestJa: {
        language: "ja",
        type: this.$route.query.type,
        name: "",
        description: "",
      },
      requests: [],
      //#endregion request

      //#region Response data
      response: [],
      //#endregion Response data

      // selectedType: this.type,
      selectedType: 14,

    };
  },

  validations() {
    return {
      requestJa: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event

    /**日本語入力中のEnterキー操作は無効 */
    onEnterKeyDown (event) {
      console.log(event.keyCode);
      if (event.keyCode !== 13) return false;
      if (event.keyCode === 13) this.isSubmit = false;
    },

    /** 確認ボタン押下 */
    onCheckClicked() {
      this.$v.requestJa.$invalid
        ? this.$v.requestJa.$touch()
        : (this.checkModal = true);
    },

    /** 登録ボタン押下 */
    onRegisterClicked() {
      this.reqPost();
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    //#endregion Event

    //#region Request
    reqPost() {
      this.requests = [];
      this.requests.push(this.requestJa);
      const body = this.requests;

      const callback = () => {
        this.checkModal = false;
        this.successModal = true;
      }

      const errorCallback = (err) => {
        this.errorMessage = `${err.name} : ${err.message}`;
        this.checkModal = false;
         this.errorModal = true;
      }

      this.reqPostFeatureMasterByType(this.selectedType,body,callback,errorCallback);
    },

    //#endregion Request

    //#region Method
    backPage() {
      this.$router.push({
        path: "/admin/feature/list",
        query: { type: this.selectedType },
      });
    },
    //#endregion Method
  },

  beforeMount() {
    this.selectedType = Number(this.$route.query.type);
  },
};
</script>
<style>

.form-control-plaintext{
  color: black;
  appearance: none;
}

</style>